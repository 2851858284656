<template>
  <v-card>
    <v-card-title>
      Приглашение участника <br/> в {{event.name}}
    </v-card-title>
    <v-card-text>
      <br/>
      <v-autocomplete  v-model="invitation.user_id" :items="availableParticipants.data"
                      :loading="!availableParticipants.isLoad" item-text="name"
                      item-value="id" label="Участники"
                       no-data-text="Такого пользователя нет на АИС" outlined>
        <template v-slot:item="data">
          <v-list-item v-bind="data.attrs" v-on="data.on"
                       :disabled="data.item.already_in_event && data.item.teams.length > 0">
            <v-list-item-avatar>
              <v-avatar size="24">
                <v-img :src="data.item.avatar"></v-img>
              </v-avatar>

            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
      </v-autocomplete>
      <v-autocomplete  v-model="invitation.event_role_id" :items="$store.state.events.currentEvent.roles"
                      outlined
                      item-text="role.name"
                      item-value="id"
                      label="Роль в мероприятии"
      />

      <p>Пользователю будет выслано приглашение, которое он должен будет принять на главной странице мероприятия. </p>
      <v-btn :loading="loading" @click="create" color="green" outlined rounded>Пригласить</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import events from "@/api/events";
import invitations from "@/api/invitations";

export default {
  name: "MakeInvitationWidget",
  data() {
    return {
      loading: false,
      availableParticipants: {
        isLoad: false,
        data: []
      },
      invitation: {
        event_role_id: null,
        user_id: null,
      }
    }
  },
  computed: {
    event(){
     return this.$store.state.events.currentEvent;
    }
  },
  methods: {
    create(){
      this.loading = true;
      invitations.create(this.invitation).then(() => {
        this.$emit('close');
        this.$store.dispatch('getEventParticipants', {id: this.$store.state.events.currentEvent.id})
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    events.getAvailableParticipants(this.event.id).then((r) => {
      this.availableParticipants.data = r.data.filter(p => !p.already_in_event);
      this.availableParticipants.isLoad = true
    });
  }
}
</script>

<style scoped>

</style>
