<template>
  <div>
    <v-row class="pa-3" justify="space-between" align-content="center">
      <v-col lg="12" cols=12>
        <v-dialog v-model="showInviteDialog" max-width="500">
          <template v-slot:activator="{ on }">
            <v-btn class="mt-2" v-on="on"
                   color="green" small outlined rounded dark>
              <v-icon left>
                mdi-plus
              </v-icon>
              Пригласить участника
            </v-btn>
          </template>
          <MakeInvitationWidget @close="showInviteDialog  = false;"/>
        </v-dialog>
        <v-btn class="mt-2 ml-2"
            :href="'https://api.student.surgu.ru/events/'+this.$store.state.events.currentEvent.id+'/participants/csv'"
            color="primary" outlined small rounded dark>
          <v-icon left>
            mdi-download
          </v-icon>
          Скачать участников
        </v-btn>
      </v-col>
      <v-col  lg="12" cols=12>
        <v-text-field
            append-icon="mdi-account-search"
            hide-details
            label="Поиск пользователя"
            single-line
            v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table  :headers="headers"
                  :items="this.$store.state.events.participants"
                  :items-per-page="1000"
                   :footer-props="{itemsPerPageText: 'Участников на странице'}"
                  :loading="loading" :search="search"
                  loading-text="Загружаем ... "
    >

      <template v-slot:item.role="props">
        <v-edit-dialog
        >
          <v-chip :key="index"
                  class="ma-2"
                  label
                  v-for="(role, index) in props.item.roles.map((el) => {return el.event_role.role.name})"
          >
            {{ role }}
          </v-chip>
          <template v-slot:input>
            <v-autocomplete :items="$store.state.events.currentEvent.roles"
                            :value="props.item.roles.map((el) => {return el.event_role.id})"
                            @input="changeParticipantRole(props.item.id, $event)"
                            color="pink"
                            item-text="role.name"
                            item-value="id"
                            label="Редактирование"
                            multiple
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip color="error" v-if="item.invited">
          Не принял приглашение
        </v-chip>
        <v-chip color="success" v-else>
          Участник
        </v-chip>
      </template>

      <template v-slot:item.is_visit="{ item }">
        <EventUserIsVisitSwitch :event="$store.state.events.currentEvent" :user="item"></EventUserIsVisitSwitch>
      </template>

      <template v-slot:item.name="{ item }">
       <UserLinkComponent :user="item"/>
      </template>

      <template v-slot:item.type="{ item }">
        <div v-if="item.role === 'student'">
          Студент
          <span v-if="item.student_group">{{ item.student_group.name }}</span> -
          <span
              v-if="item.student_group && item.student_group.department">{{ item.student_group.department.name }}</span>
        </div>
        <div v-if="item.role === 'employee'">
          {{ item.employee_post }}
          <span v-if="item.department">{{ item.department.name }}</span>

        </div>
        <div v-if="item.role === 'pupil'">
          {{ item.pupil_school }}
          {{ item.pupil_class }}
        </div>
        <div v-if="item.role === 'visitor'">
          Посетитель
        </div>

      </template>

    </v-data-table>
  </div>

</template>

<script>
//  import EventUserCheckSwitch from "@/components/OldEvents/EventParticipants/EventUserCheckSwitch";
import EventUserIsVisitSwitch from "@/components/OldEvents/EventParticipants/EventUserIsVisitSwitch";
import MakeInvitationWidget from "@/components/Events/EventParts/Widgets/MakeInvitationWidget";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";

export default {
  name: "EventParticipantComponent",
  components: {UserLinkComponent, MakeInvitationWidget, EventUserIsVisitSwitch},
  data() {
    return {
      loading: true,
      showInviteDialog: false,
      search: "",
      headers: [
        {
          text: '#',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {text: 'Фамилия Имя', value: 'name'},
        {text: 'Статус', value: 'status'},
        {text: 'Тип', value: 'type'},
        {text: 'Роль', value: 'role'},
        {text: 'Присутствие', value: 'is_visit'},
      ],
    }
  },
  methods: {
    changeParticipantRole(user_id, roles) {
      // eslint-disable-next-line no-console
      console.log(user_id, roles)
      this.$store.dispatch('changeParticipantRole', {
        event_id: this.$store.state.events.currentEvent.id,
        user_id,
        roles
      })
    }
  },
  mounted() {
    this.$store.dispatch('getEventParticipants', {id: this.$store.state.events.currentEvent.id}).then(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped>

</style>
